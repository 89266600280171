@use '../breakpoints/breakpoints.scss';
@use '../variables/colors';
@use '../variables/fonts';
@use '../mixin/fonts-style.scss';


.box-button-menu {
    display: block;
    margin-right: 10px;
}


// card item property
.image-card-box {
    width: 100%;
    cursor: pointer;
    .card-image > a {
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
    .zoom-effect-container {
        position: relative;
        width: 100%;
        height: 195px;
        overflow: hidden;
        .view-tite {
            margin-top: 5px;
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #2d2a6d;
            opacity: 1;
            padding: 5px 10px;
            position: absolute;
            z-index: 100;
            border-radius: 5px;
            color: #ddd;
        }
    }

    .image-card {
        position: absolute;
        top: 0;
        left: 0;
        img { 
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    .image-card img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .zoom-effect-container:hover .image-card img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}


.image-card-box-algolia {
    width: 100%;
    cursor: pointer;
    .card-image > a {
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
    .zoom-effect-container {
        position: relative;
        width: 100%;
        height: 175px;
        overflow: hidden;
        .view-tite {
            margin-top: 5px;
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #2d2a6d;
            opacity: 1;
            padding: 5px 10px;
            position: absolute;
            z-index: 100;
            border-radius: 5px;
            color: #ddd;
        }
    }

    .image-card {
        position: absolute;
        top: 0;
        left: 0;
        img { 
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    .image-card img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .zoom-effect-container:hover .image-card img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

// card item property similar
.image-card-similar {
    width: 100%;
    cursor: pointer;
    .card-image > a {
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
    .zoom-effect-container {
        position: relative;
        width: 100%;
        height: 155px;
        overflow: hidden;
        .view-tite {
            margin-top: 5px;
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #2d2a6d;
            opacity: 1;
            padding: 5px 10px;
            position: absolute;
            z-index: 100;
            border-radius: 5px;
            color: #ddd;
        }
    }

    .image-card {
        position: absolute;
        top: 0;
        left: 0;
        img { 
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    .image-card img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .zoom-effect-container:hover .image-card img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.image-card-similar-detail {
    width: 100%;
    cursor: pointer;
    .card-image > a {
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
    .zoom-effect-container {
        position: relative;
        width: 100%;
        height: 205px;
        overflow: hidden;
        .view-tite {
            margin-top: 5px;
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #2d2a6d;
            opacity: 1;
            padding: 5px 10px;
            position: absolute;
            z-index: 100;
            border-radius: 5px;
            color: #ddd;
        }
    }

    .image-card {
        position: absolute;
        top: 0;
        left: 0;
        img { 
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    .image-card img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .zoom-effect-container:hover .image-card img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

// card item city
.city-card-box {
    width: 100%;
    cursor: pointer;

    .zoom-effect-container-city {
        position: relative;
        height: 200px;
        overflow: hidden;
        .view-title-city {
            background-color: #2d2a6d;
            font-size: 20px;
            padding: 5px 10px;
            font-weight: 300;
            // opacity: 0.8;
            margin-top: 20%;
            z-index: 100;
            border-radius: 5px;
            color: #fff;
        }
    }
    
    .image-card-city {
        position: absolute;
        top: 0;
        left: 0;
        // opacity: 0.8;
        img { 
            width: 100%;
            border-radius: 5px;
        }
    }
    
    .image-card-city img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
    } 
    
    .zoom-effect-container-city:hover .image-card-city img {
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
    }
}


.title-price-similar {
    padding-top: 10px;
    padding-bottom: 10px;
    h1 {
        font-weight: 600;
        color: #F49614;
        font-size: 25px;
    }
    h3 {
        padding-top: 5px;
        font-weight: 200;
        color: #8aa7b2;
        text-decoration: line-through;
    }
    h4 {
        padding-top: 5px;
        font-weight: 200;
        font-size: 22px;
        color: #8aa7b2;
        text-decoration: line-through;
    }
    h2 { 
        font-weight: 600;
        color: #F49614;
    }
    p {
        font-weight: 400;
        padding-top: 10px;
    }
    h6 {
        font-size: 20px;
        font-weight: 400;
        padding-top: 10px;
    }
}

.title-name-similar {
    h4 {
        font-weight: 400;
        font-size: 15px;
    }
    padding-bottom: 5px;
}

@include breakpoints.media('<=phone') {
    .box-button-menu {
        margin-right: 5px;
        a {
            font-size: 13px;
        }
        padding-bottom: 7px;
    }
    .button-outlined-custom {
        text-align: center !important;
        justify-content: center !important;
        cursor: pointer;
        margin-right: 5px;
        padding: 2px 7px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .button-contained-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        padding: 10px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .text-property-polihan {
        color: #2d2a6d;
        h5 {
            font-size: 12px;
            font-weight: 500;
        }
    }
    .groperti-about {
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        .kisah {
            padding-top: 10px;
            h2 {
                font-size: 17px;
                font-weight: 500;
            }
        }

        .caption-about {
            width: 95%;
            padding-top: 20px;
            padding-bottom: 20px;
            p {
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                line-height: normal
            }
        }
    }
    .groperti-faq {
        h2 {
            font-size: 20px;
            font-weight: 500;
        }
        h5 {
            padding-top: 10px;
            font-size: 13px;
            font-weight: 600;
        }
        p {
            font-size: 13px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: normal
        }
    }
    .image-empty {
        img {
            width: 200px;
        }
    }
    .img-notfound {
        margin-bottom: 10px;
        img {
            width: 200px;
        }
    }
    .text-notfound {
        text-align: center;
    }
}



.title-price-algolia {
    padding-top: 10px;
    padding-bottom: 10px;
    h1 {
        font-weight: 600;
        color: #F49614;
        font-size: larger;
    }
    h3 {
        padding-top: 5px;
        font-weight: 200;
        color: #8aa7b2;
        font-size: 20px;
        text-decoration: line-through;
    }
    h4 {
        padding-top: 5px;
        font-weight: 200;
        color: #8aa7b2;
        font-size: 20px;
    }
    h2 {
        font-weight: 600;
        color: #F49614;
    }
    p {
        font-weight: 400;
        padding-top: 10px;
        font-size: 12px
    }
    h6 {
        font-size: 20px;
        font-weight: 400;
        padding-top: 10px;
    }
}

.title-name-algolia{
    h4 {
        font-weight: 400;
        font-size: 15px;
    }
}

// .title-address {
//     padding-top: 5px;
//     h5 {
//         font-weight: 400;
//         font-size: 12px;
//     }
// } 

// .icon-card {
//     display: flex;
//     padding-top: 15px;
//     align-items: center;
//     justify-content: space-between; 
// }


// .card-image {
//     width: 500px;
//     height: 400px;
//     .title-card-menu {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         cursor: pointer;
//         position: absolute;
//         z-index: 1;
//         padding-top: 100px;
//         padding-left: 100px;
//         text-align: center;
//         h2 {
//             font-size: 30px;
//             color: #ddd;
//         }
//     }
//     img {
//         width: 100%;
//         height: 100%;
//     }
// }



@include breakpoints.media('<=phone', '>=370px') {
    .box-button-menu {
        margin-right: 5px;
        a {
            font-size: 13px;
        }
        padding-bottom: 7px;
    }
    .button-outlined-custom {
        text-align: center !important;
        justify-content: center !important;
        cursor: pointer;
        margin-right: 5px;
        padding: 2px 7px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .button-contained-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        padding: 10px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .text-property-polihan {
        color: #2d2a6d;
        h5 {
            font-size: 12px;
            font-weight: 500;
        }
    }
    .groperti-about {
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        .kisah {
            padding-top: 10px;
            h2 {
                font-size: 17px;
                font-weight: 500;
            }
        }

        .caption-about {
            width: 95%;
            padding-top: 20px;
            padding-bottom: 20px;
            p {
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                line-height: normal
            }
        }
    }
    .groperti-faq {
        h2 {
            font-size: 20px;
            font-weight: 500;
        }
        h5 {
            padding-top: 10px;
            font-size: 13px;
            font-weight: 600;
        }
        p {
            font-size: 13px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: normal
        }
    }
    .image-empty {
        img {
            width: 200px;
        }
    }
    .img-notfound {
        margin-bottom: 10px;
        img {
            width: 200px;
        }
    }
    .text-notfound {
        text-align: center;
    }

    .slick-slide img {
        margin: auto;
        height: 300px;
    }
}

@include breakpoints.media('>=371px', '<=599px') {
    .box-button-menu {
        display: none;
        a {
            font-size: 15px;
        }
        padding-bottom: 10px;
    }
    .button-outlined-custom {
        text-align: center !important;
        justify-content: center !important;
        cursor: pointer;
        margin-right: 5px;
        padding: 4px 10px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
        margin-top: 10px;
    }
    .button-contained-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        padding: 12px;
        border-radius: 5px;
        font-size: 15px;
        margin-top: 10px;
        font-weight: 300;
    }
    .text-property-polihan {
        color: #2d2a6d;
        h5 {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .groperti-about {
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        .kisah {
            padding-top: 10px;
            h2 {
                font-size: 17px;
                font-weight: 500;
            }
        }

        .caption-about {
            width: 85%;
            padding-top: 20px;
            padding-bottom: 20px;
            p {
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                line-height: normal
            }
        }
    }
    .groperti-faq {
        h2 {
            font-size: 20px;
            font-weight: 500;
        }
        h5 {
            padding-top: 10px;
            font-size: 13px;
            font-weight: 600;
        }
        p {
            font-size: 13px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: normal
        }
    }
    
    .image-empty {
        img {
            width: 300px;
        }
    }
    .img-notfound {
        margin-bottom: 20px;
        img {
            width: 200px;
        }
    }
    .text-notfound {
        text-align: center;
    }
    
    .slick-slide img {
        margin: auto;
        height: 300px;
    }
}


@include breakpoints.media('>=600px', '<=tablet') {
    .box-button-menu {
        display: none;
        margin-right: 10px;
        a {
            font-size: 13px;
        }
    }
    .button-outlined-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        padding: 4px 10px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .button-contained-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        padding: 15px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .text-property-polihan {
        color: #2d2a6d;
        h5 {
            font-size: 15px;
            font-weight: 500;
        }
    }
    .groperti-about {
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        .kisah {
            padding-top: 10px;
            h2 {
                font-size: 17px;
                font-weight: 500;
            }
        }

        .caption-about {
            width: 85%;
            padding-top: 20px;
            padding-bottom: 20px;
            p {
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                line-height: normal
            }
        }
    }
    .groperti-faq {
        h2 {
            font-size: 22px;
            font-weight: 500;
        }
        h5 {
            padding-top: 10px;
            font-size: 15px;
            font-weight: 600;
        }
        p {
            font-size: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: normal
        }
    }

    .image-empty {
        padding-top: 50px;
        img {
            width: 300px;
        }
    }
    .img-notfound {
        margin-bottom: 50px;
        img {
            width: 300px;
        }
    }
    .text-notfound {
        text-align: center;
    }

    .slick-slide img {
        margin: auto;
        height: 350px;
    }
}


@include breakpoints.media('>tablet', '<=desktop') {
    .button-outlined-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .button-contained-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
        padding: 11px 20px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .text-property-polihan {
        color: #2d2a6d;
        h5 {
            font-size: 20px;
            font-weight: 500;
        }
    }
    .groperti-about {
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        .kisah {
            padding-top: 10px;
            h2 {
                font-size: 17px;
                font-weight: 500;
            }
        }

        .caption-about {
            width: 75%;
            padding-top: 20px;
            padding-bottom: 20px;
            p {
                font-size: 13px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                line-height: normal
            }
        }
    }
    .groperti-faq {
        h2 {
            font-size: 22px;
            font-weight: 500;
        }
        h5 {
            padding-top: 10px;
            font-size: 15px;
            font-weight: 600;
        }
        p {
            font-size: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: normal
        }
    }

    .image-empty {
        padding-top: 50px;
        img {
            width: 400px;
        }
    }
    .img-notfound {
        margin-bottom: 50px;
        img {
            width: 500px;
        }
    }
    .text-notfound {
        text-align: center;
    }

    .slick-slide img {
        margin: auto;
        height: 600px;
    }
}

@include breakpoints.media('>desktop') {
    .button-outlined-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .button-contained-custom {
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
        padding: 11px 20px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 300;
    }
    .text-property-polihan {
        color: #2d2a6d;
        h5 {
            font-size: 25px;
            font-weight: 500;
        }
    }
    .card-why {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .title-why {
            h2 {
                font-weight: 500;
            }
        }
        .caption-why {
            padding-top: 10px;
            h4 {
                font-weight: 400;
                font-size: 17px;
            }
        }
    }

    .groperti-about {
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 23px;
            font-weight: 500;
        }

        .kisah {
            padding-top: 10px;
            h2 {
                font-size: 22px;
                font-weight: 500;
            }
        }

        .caption-about {
            width: 75%;
            padding-top: 20px;
            padding-bottom: 20px;
            p {
                font-size: 17px;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                line-height: normal
            }
        }
    }

    .groperti-faq {
        h2 {
            font-size: 22px;
            font-weight: 500;
        }
        h5 {
            padding-top: 10px;
            font-size: 15px;
            font-weight: 600;
        }
        p {
            font-size: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: normal
        }
    }

    .image-empty {
        padding-top: 100px;
        img {
            width: 500px;
        }
    }
    .img-notfound {
        margin-bottom: 50px;
        img {
            width: 500px;
        }
    }
    .text-notfound {
        text-align: center;
    }

    .slick-slide img {
        margin: auto;
        height: 680px;
    }
}
