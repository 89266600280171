@use '../breakpoints/breakpoints.scss';
@use '../variables/colors';
@use '../variables/fonts';
@use '../mixin/fonts-style.scss';


.container-auth {
    padding: 100px 0;
    .title-daftar {
        text-align: center;
        h1 {
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(32px,800,0px);
        }
        p {
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(17px,600,0.1px);
        }
    }
    
    .title-label {
        font-weight: 500;
        padding-top: 10px;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(17px,600,0.1px);
    }
    
    .container-form {
        margin: 2rem 0;
        min-width: 500px;
    }

    
}

@include breakpoints.media('<=phone') {
    .logo-groperti > a {
        img {
            width: 100px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .container-auth {
        padding: 5px 0;
        .container-form {
            margin: 2rem 0;
            min-width: 200px;
        }
        .title-daftar {
            text-align: center;
            h1 {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(20px,800,0px);
            }
            p {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(12px,600,0.1px);
            }
        }
    }
    .container-layout {
        flex-grow: 1;
        padding: 0;
    }
    .layout-box {
        max-width: 370px;
    }
}

@include breakpoints.media('>phone', '<=599px') {
    .logo-groperti > a {
        img {
            width: 120px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .logo-groperti-footer > a {
        img {
            width: 120px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .container-auth {
        padding: 5px 0;
        .container-form {
            margin: 2rem 0;
            min-width: 200px;
        }
        .title-daftar {
            text-align: center;
            h1 {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(25px,800,0px);
            }
            p {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(14px,600,0.1px);
            }
        }
    }
    .container-layout {
        flex-grow: 1;
        padding: 0;
    }
    .layout-box {
        max-width: 380px;
    }
}

@include breakpoints.media('>=600px', '<=tablet') {
    .logo-groperti > a {
        img {
            width: 150px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .logo-groperti-footer > a {
        img {
            width: 120px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .container-layout {
        flex-grow: 1;
        padding: 20px;
    }
}


@include breakpoints.media('>tablet', '<=desktop') {
    .logo-groperti > a {
        img {
            width: 150px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .logo-groperti-footer > a {
        img {
            width: 120px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .container-layout {
        flex-grow: 1;
        padding: 20px;
    }
}

@include breakpoints.media('>desktop') {
    
    .logo-groperti > a {
        img {
            width: 150px;
        }
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    .container-layout {
        flex-grow: 1;
        padding: 20px;
    }
   
}
