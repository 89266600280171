$primaryColor : #333333;
$secondColor : #525252;
$whiteColor : #fafafa;
$whiteMain : #fafafa;
$blackMain : #000000;
$primaryBlue : #2d2a6d;

$primaryMain : #023047;
$primaryDark : #091a24;
$primaryLight : #617E8C;
$primaryLight2 : #1A4458;
$secondaryMain : #fb8500;
$secondaryLight : #debcaa;
$brownMain : #212121;
$grayMain : #343e47;
$grayLight : #c7cadc;
