@use '../breakpoints/breakpoints.scss';
@use '../variables/colors';
@use '../variables/fonts';
@use '../mixin/fonts-style.scss';


#custom-font {
    font-family: 'Mulish';

    h2 {
        font-weight: 800;
    }
}


.headline-container {
    padding: 100px 0;
    img {
        width: 220px;
        height: 220px;
    }
    h1 {
        font-size: 2.5rem;
        font-weight: 500;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(32px,800,0px);
    }
    h6 {
        width: 90%;
        padding-top: 0.3rem;
        font-size: 1.5rem;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(17px,400,0px);
    }
    .btn-listing {
        cursor: pointer;
        margin-top: 2rem;
        width: max-content;
        background-color: colors.$secondaryMain;
        border: none;
        border-radius: 0.5rem;
        transition: 0.3s;
        p {
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
            color: colors.$whiteColor;
            @include fonts-style.font-mulish(20px,400,0.5px);
        }
    }
    .btn-listing:hover {
        cursor: pointer;
        margin-top: 2rem;
        width: max-content;
        border: none;
        border-radius: 0.5rem;
        opacity: 0.9;
        p {
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
            color: colors.$whiteColor;
            @include fonts-style.font-mulish(20px,400,0.5px);
        }
    }
    
}

.benefit-container {
    padding: 100px 0;
    img {
        width: 250px;
        height: 250px;
    }
    h1 {
        font-size: 2.5rem;
        font-weight: 500;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(30px,800,0.5px);
    }
    p {
        padding-top: 0.3rem;
        font-size: 1.5rem;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(15px,400,0.5px);
    }
    .listing {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
        float: left;
        h2 {
            padding-top: 10px;
            font-weight: 500;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(20px,800,0.5px);
        }
        img {
            width: 100px;
            height: 100px;
        }
    }
    
}

.action-container {
    padding: 70px 0;
    .mulai {
        // padding: 5px 15px;
        // border-radius: 10px;
        border-bottom: colors.$primaryBlue 2px solid;
        h1 {
            font-size: 2.5rem;
            font-weight: 500;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(30px,800,0.5px);
        }
    }
    .btn-listing {
        cursor: pointer;
        margin-top: 2rem;
        width: max-content;
        p {
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
            color: colors.$whiteColor;
            background-color: colors.$secondaryMain;
            border: none;
            border-radius: 0.5rem;
            @include fonts-style.font-mulish(20px,400,0.5px);
        }
    }
    .btn-listing:hover {
        cursor: pointer;
        margin-top: 2rem;
        width: max-content;
        border: none;
        border-radius: 0.5rem;
        opacity: 0.9;
        p {
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
            color: colors.$whiteColor;
            @include fonts-style.font-mulish(20px,400,0.5px);
        }
    }
}

.container-auth {
    padding: 100px 0;
    .title-daftar {
        text-align: center;
        h1 {
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(35px,800,0px);
        }
        p {
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(17px,600,0.1px);
        }
    }
    
    .title-label {
        font-weight: 500;
        padding-top: 10px;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(17px,600,0.1px);
    }
    
    .container-form {
        margin: 2rem 0;
        min-width: 500px;
    }
}

.section-form-company-detail {
    h3 {
        font-weight: 500;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(20px,800,0.1px);
    }
    .title-label {
        font-weight: 500;
        padding-top: 10px;
        color: colors.$primaryBlue;
        @include fonts-style.font-mulish(17px,600,0.1px);
    }
}

@include breakpoints.media('<phone',) {
    .headline-container {
        padding: 40px 0;
        img {
            width: 200px;
            height: 200px;
        }
        h1 {
            font-weight: 500;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(20px,800,0.5px);
        }
        h6 {
            padding-top: 0.1rem;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(12px,400,0.5px);
        }
        .btn-listing {
            margin-top: 2rem;
            width: max-content;
            p {
                padding: 0.5rem 1rem;
                color: colors.$whiteColor;
                background-color: colors.$secondaryMain;
                border: none;
                border-radius: 0.5rem;
                @include fonts-style.font-mulish(15px,400,0.5px);
            }
        }
    }
    .benefit-container {
        padding: 50px 0;
        text-align: center;
        h1 {
            @include fonts-style.font-mulish(18px,800,0.5px);
        }
        p {
            padding-top: 0.3rem;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(12px,400,0.5px);
        }
        .listing {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px auto;
            h2 {
                text-align: center;
                padding-top: 10px;
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(12px,400,0.5px);
            }
            img {
                width: 40px;
                height: 40px;
            }
        }   
    }
    .action-container {
        padding: 50px 0;
        .mulai {
            text-align: center;
            h1 {
                font-weight: 500;
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(20px,800,0.5px);
            }
        }
        .btn-listing {
            cursor: pointer;
            margin-top: 2rem;
            width: max-content;
            p {
                padding: 0.5rem 1rem;
                font-size: 1.5rem;
                color: colors.$whiteColor;
                background-color: colors.$secondaryMain;
                border: none;
                border-radius: 0.5rem;
                @include fonts-style.font-mulish(17px,400,0.5px);
            }
        }
    }

    .container-auth {
        padding: 5px 0;
        .container-form {
            margin: 2rem 0;
            min-width: 200px;
        }
        .title-daftar {
            text-align: center;
            h1 {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(20px,800,0px);
            }
            p {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(12px,600,0.1px);
            }
        }
    }
}

@include breakpoints.media('>=phone', '<=tablet') {
    .headline-container {
        padding: 50px 0;
        img {
            width: 200px;
            height: 200px;
        }
        h1 {
            width: 80%;
            font-weight: 500;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(22px,800,0px);
        }
        h6 {
            // width: 70%;
            padding-top: 0.1rem;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(13px,400,0px);
        }
        .btn-listing {
            margin-top: 2rem;
            width: max-content;
            p {
                padding: 0.5rem 1rem;
                color: colors.$whiteColor;
                background-color: colors.$secondaryMain;
                border: none;
                border-radius: 0.5rem;
                @include fonts-style.font-mulish(15px,400,0.5px);
            }
        }   
        .btn-listing:hover {
            cursor: pointer;
            margin-top: 2rem;
            width: max-content;
            border: none;
            border-radius: 0.5rem;
            opacity: 0.9;
            p {
                padding: 0.5rem 1rem;
                font-size: 1.5rem;
                color: colors.$whiteColor;
                @include fonts-style.font-mulish(15px,400,0.5px);
            }
        }
    }
    .benefit-container {
        padding: 50px 0;
        text-align: center;
        h1 {
            @include fonts-style.font-mulish(20px,800,0.5px);
        }
        p {
            padding-top: 0.3rem;
            color: colors.$primaryBlue;
            @include fonts-style.font-mulish(12px,400,0.5px);
        }
        .listing {
            // margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px auto;
            h2 {
                text-align: center;
                padding-top: 10px;
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(14px,500,0.5px);
            }
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
    .action-container {
        padding: 70px 0;
        .mulai {
            text-align: center;
            border-bottom: colors.$primaryBlue 2px solid;
            h1 {
                font-weight: 500;
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(20px,800,0.5px);
            }
        }
        .btn-listing {
            cursor: pointer;
            margin-top: 2rem;
            width: max-content;
            p {
                padding: 0.5rem 1rem;
                font-size: 1.5rem;
                color: colors.$whiteColor;
                background-color: colors.$secondaryMain;
                border: none;
                border-radius: 0.5rem;
                @include fonts-style.font-mulish(17px,400,0.5px);
            }
        }
        .btn-listing:hover {
            cursor: pointer;
            margin-top: 2rem;
            width: max-content;
            border: none;
            border-radius: 0.5rem;
            opacity: 0.9;
            p {
                padding: 0.5rem 1rem;
                font-size: 1.5rem;
                color: colors.$whiteColor;
                @include fonts-style.font-mulish(17px,400,0.5px);
            }
        }
    }

    .container-auth {
        padding: 5px 0;
        .container-form {
            margin: 2rem 0;
            min-width: 200px;
        }
        .title-daftar {
            text-align: center;
            h1 {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(25px,800,0px);
            }
            p {
                color: colors.$primaryBlue;
                @include fonts-style.font-mulish(14px,600,0.1px);
            }
        }
    }
}