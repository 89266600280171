
.logo-groperti {
    img {
        width: 100%;
    }
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-color: #2d2a6d;
    a {
        font-size: 25px;
        color: #fff;
        font-weight: 600;
    }
}


.logo-mpi {
    margin-top: 5px;
    img {
        width: 100px;
    }
    flex-grow: 1;
    background-color: #2d2a6d;
}

.logo-mpi-p {
    margin-right: 20px;
    // margin-top: 10px;
    margin-left: 0px !important;
    img {
        width: 25px;
    }
    // border-right: #000 solid 1px;
}

.right-navbar {
    display: flex;
    cursor: pointer;
    color: #fff;
}

.text-navbar {
    a {
        color: #e8f0f5;
    }
}

.popper-style {
    margin-top: 20px;
}



.sidebar-navbar {
    font-size: 15px;
    ul {
        padding-left: 15px;
        list-style: none;
        margin: 0;
        background-color: #fff;
        display: flex;
        li {
            span {
                color: #000;
                padding-top: 5px;
            }
            padding: 9px

        }
        .navbar-list {
            display: flex;
            align-items: center;
            .icon {
                color: #2d2a6d;
                margin-right: 10px;
                margin-top: 5px;
            }
        }
    }
}
.active-link {
    ul {
        border-right: 5px solid #2d2a6d;
        li {
            span {
                color: #2d2a6d;
                font-weight: 600;
            }
        }
        .navbar-list {
            display: flex;
            align-items: center;
        }
    }
    
}
    

.sidebar-navbar-dropdown {
    padding-top: 15px;
    font-size: 15px;
    .menu-dropdown {
        padding-left: 27px;
        padding-right: 27px;
        padding-bottom: 5px;
        cursor: pointer;
    }
    .menu-list-dropdown {
        display: none;
    }
    .menu-list-dropdown-active {
        display: block;
        .navbar-list {
            padding-left: 30px;           
        }
    }
}